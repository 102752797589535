<div class="more-container container">
  <div class="more-css">
    <ul *ngIf="role">
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)" (click)="goStock()">Stock Prices</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="universe()">Universe</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="subscriber()">Subscribers</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="holiday()">Holiday Lists</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="promocode()">Promo Codes</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="referral()">Referrers</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="refUserList()">Referrer Users</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="adminUser()">Admin Users</li>
      <li *ngIf="!['subscriber'].includes(role)"(click)="pouchpayments()">Pouch Payments</li>
      <li *ngIf="!['ria', 'partner','partner_admin','subscriber'].includes(role)"(click)="bonusSplit()">Bonus & Split</li>
    </ul>
  </div>
</div>
<!-- <div class="more-container ui-update">
    <div>
        <ul>
            <li (click)="goStock()">Stock Prices</li>
            <li (click)="universe()">Universe</li>
            <li (click)="subscriber()">Subscribers</li>
            <li (click)="holiday()">Holiday List</li>
            <li (click)="promocode()">Promo Code</li>
        </ul>
    </div>
</div> -->
