import { DecimalPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';

import * as _moment from 'moment';
import * as moment from 'moment';
import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { SharedService } from 'src/app/common/services/shared.service';
import { NotificationService } from 'src/app/services/notification.services';
import { SubsciberService } from 'src/app/services/subscriber.service';
import { environment } from 'src/environments/environment';

import { MapSubscriberComponent } from '../../investment-pouches/map-subscriber/map-subscriber.component';
import { PouchCompositionComponent } from '../../investment-pouches/pouch-composition/pouch-composition.component';
import { PouchService } from '../../investment-pouches/services/pouch.service';
import { PaymentLoaderComponent } from '../../navigation/payment-loader/payment-loader.component';
import { SucessPopupComponent } from '../../public/components/sucess-popup/sucess-popup.component';
import { PublicService } from '../../public/services/public.service';
import { CommonDialogWaitingComponent } from '../common-dialog/common-dialog.component';
import { ExecuteTradePopupComponent } from '../execute-trade-popup/execute-trade-popup.component';
import { PlayVideoPopupComponent } from '../play-video-popup/play-video-popup.component';
import { RenewSubscriptionPopupComponent } from '../renew-subscription-popup/renew-subscription-popup.component';
import { RiskProfileComponent } from '../risk-profile/risk-profile.component';
import { SubscriptionRenewPopupComponent } from '../subscription-renew-popup/subscription-renew-popup.component';
import { WarningKycRpPopupComponent } from './../../../warning-kyc-rp-popup/warning-kyc-rp-popup.component';
import { AmountUpdatePopupComponent } from './amount-update-popup/amount-update-popup.component';
import {DeletePopupComponent} from "../../../common/components/delete-popup/delete-popup.component";

export interface AmountUpdatePopupData {
  pouchId: string | number;
  pouchFrequency: string;
  subscriberInvestmentAmount: string;
  subscriberID: string;
  min_investment: any;
  pouchStatus: boolean;
  pouchName:string
}
@Component({
  selector: 'app-banner-subscription-details',
  templateUrl: './banner-subscription-details.component.html',
  styleUrls: ['./banner-subscription-details.component.scss'],
  providers: [DecimalPipe]
})
export class BannerSubscriptionDetailsComponent{
  showRpWarning = false;
  @Input() since: any;
  @Input() calculateSubFee: boolean;
  @Input() is_pending_stoct: boolean;
  @Input() agreementStatus: any;
  @Input() PouchSubscriberStocksReturns: any;
  @Input() calculatedMinAmount: any;
  @Input() pouchDetail: any;
  @Input() tabIndex: any;
  @Input() byPassToPayment: any;
  @Input() subscriptionStatus: any;
  @Input() inputBannerType: any;
  @Output() updateTabeleHandler = new EventEmitter();
  @Output() updateSubscriberListHandler = new EventEmitter();
  @Output() bypassToPaymentHandler = new EventEmitter();
  @Output() tabIndexChanger = new EventEmitter();
  @Input() investmentChange: boolean;
  @Input() holdStockLength: any;
  @Input() pouchId: any;
  @Input() parent:any
  gatewayInstance = new window.scDK({
    // ! Use valid gateway name here
    gateway: 'smartvalues',
    /*
     * ! Use valid auth token
     * Details - https://developers.gateway.smallcase.com/docs/getting-started#broker-identification
     */
    smallcaseAuthToken: environment.smallcaseAuthToken,
    config: {
      // Should allow after market orders?
      amo: true
    }
  });
  bannerType: any = 0;
  sub_fee: any;
  isPending = false;
  user_notSubscribed = true;
  user_Subscribed_notInvested = false;
  user_subscribed_andInvested = false;
  subscriptionsList: any;
  minimumInvestmentAmountCopy: any;
  min_investment: number = 0;
  subscriberSubscription: any;
  intialLoad = true;
  stockLength: any;
  updateAmnt=true
  userData: any;
  constructor(
    public parentcomp: MapSubscriberComponent,
    public router: Router,
    public pouchService: PouchService,
    private notifyService: NotificationService,
    private http: HttpClient,
    public dialogRef: MatDialog,
    public dialog: MatDialog,
    private sharedService: SharedService,
    private publicService: PublicService,
    private subscriberService: SubsciberService,
    private decimalpipe: DecimalPipe,

  ) {
    this.stockLength = subscriberService;
  }
  ngOnChanges(changes: any): void {

    if (this.intialLoad) {
      this.intialLoad = false;
    } else {
      this.intiate();
    }
    if (this.PouchSubscriberStocksReturns?.some((x: any) => x.txn_status == "PLACED")) {
      this.updateAmnt=false
    } else {
      this.updateAmnt=true
    }

  }
  ngAfterViewInit(): void {
    this.userData = JSON.parse(localStorage.getItem('user') || '{}');
    this.intiate();
  }
  intiate() {
    this.min_investment = this.pouchDetail.min_amt;
    this.minimumInvestmentAmountCopy = this.pouchDetail.min_amt;
    this.getSubscriptionPlanList();
    if (this.pouchDetail.subscribed === 'Y') {
      this.bannerType = this.inputBannerType;
      if (this.bannerType === 1) {
        this.subscriberSubscription = this.inputBannerType == 1 ? this.subscriptionStatus : [];
      } else if (this.bannerType === 2) {
        this.subscriberSubscription = this.inputBannerType == 2 ? this.subscriptionStatus : [];
      }

      this.daysLeftForRenewal();
    } else {
      this.bannerType = 0;
    }

    this.getSubscriptionPlanList();
  }
  openVideo() {
    let videoLink = 'https://www.youtube.com/embed/H0tzMo2s7II?autoplay=1';
    this.dialog.open(PlayVideoPopupComponent, {
      data: { videoLink: videoLink },
      width: '800px',
      minHeight: '460px',
      height: '100%',
      maxHeight: '460px',
      panelClass: 'videoPopupContainer'
    });
  }
  getminInvestmentAmount() {
    let data: any = {
      pouch_id: this.pouchDetail.id,
      stock_price_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0]
    };
    this.pouchService.getPouchStocksReturns(data).subscribe(
      (response: any) => {
        let returns: any = response[0].Results[0].return_data.pouch_stocks_returns;
        let sumArr: number[] = [];
        if (returns) {
          for (let data of returns) {
            if (!data.sell_date) {
              sumArr.push(data.close_price * data.stock_tf_count);
            }
          }
          let sum = 0;
          for (let i = 0; i < sumArr.length; i++) {
            sum += sumArr[i];
          }

          this.min_investment = Number(Math.round(sum).toFixed(2));
        }
        this.getSubscriptionPlanList();
      },
      (e) => {
        this.notifyService.showError('', e.error.error.message);
      }
    );

    // this.getSubscriptionPlanList();
  }
  getSubscriptionPlanList() {
    this.subscriptionsList = this.pouchDetail.public___pouch_subscriptions[0].subscription_freq
      ? this.pouchDetail.public___pouch_subscriptions
      : [];
    let type = this.subscriptionsList.find((ele: any) => ele.rowIndex == 1);

    if (Object.keys(this.agreementStatus).length > 0) {
      this.sub_fee = `${this.decimalpipe.transform(this.agreementStatus[0].public___subscriber_investments[0].subscription_amount_final, '1.0-0')}/${this.agreementStatus[0].public___subscriber_investments[0].subscription_freq}`;
    } else {
      if (this.calculateSubFee) {
        this.sub_fee = `${Math.round(
          this.pouchDetail.public___pouch_subscriptions[0].subscription_amount_final
        ).toFixed(0)} / ${type?.subscription_freq} `;
        return;
      } else {
        this.sub_fee = type?.subscription_freq
          ? `${this.getamount(type?.subscription_freq)} / ${type?.subscription_freq} `
          : '-';
      }
    }
  }

  getamount(type: any) {
    let getsubscriptiondata = this.subscriptionsList?.find(
      (ele: any) => ele.subscription_freq === type
    );
   
    
    if (getsubscriptiondata?.subscription_type === 'MAX') {
      let value = Math.max(((this.minimumInvestmentAmountCopy * getsubscriptiondata?.subscription_perc)/100) / ((type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),(getsubscriptiondata?.subscription_amount)
      );
      let current_available_amount:any =  Number(value || 0);
      if(getsubscriptiondata?.discount_perc){
        let discount_value = ((current_available_amount*(getsubscriptiondata?.discount_perc||0))/100)
        
        current_available_amount = Number(current_available_amount)- Number(discount_value)
      }
      return Math.ceil(current_available_amount).toFixed(2)||0 
    }
    if (getsubscriptiondata?.subscription_type === 'MIN') {
      let value = Math.min(
        (this.minimumInvestmentAmountCopy * getsubscriptiondata?.subscription_perc) /
          (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)),
        (getsubscriptiondata?.subscription_amount)
      );
      let current_available_amount:any =  Number(value || 0);
      if(getsubscriptiondata?.discount_perc){
        let discount_value = (current_available_amount*(getsubscriptiondata?.discount_perc || 0))/100
        current_available_amount = Number(current_available_amount)- Number(discount_value)
      }
      return Math.ceil(current_available_amount).toFixed(2)||0 
    }
    if (getsubscriptiondata?.subscription_type === 'BOTH') {
      let value =
        (this.minimumInvestmentAmountCopy * getsubscriptiondata?.subscription_perc) /
          (100 * (type === 'MONTHLY' ? 12 : type === 'QUARTERLY' ? 4 : 2)) +
        (getsubscriptiondata?.subscription_amount);
        let current_available_amount:any =  Number(value || 0);
        if(getsubscriptiondata?.discount_perc){
          let discount_value = (current_available_amount*(getsubscriptiondata?.discount_perc||0))/100
          current_available_amount = Number(current_available_amount)- Number(discount_value)
        }
        return Math.ceil(current_available_amount).toFixed(2)||0 
    }
    return this.minimumInvestmentAmountCopy;
  }
  checkSubscriptionActive() {
    let user = JSON.parse(localStorage.getItem('user') || '{}').attributes;
    let kycDate = moment(user.kyc_date);
    let today = moment();

      if(user.risk_profile === 'PENDING'){
        return false;
      }
    if (
      (user.risk_profile === 'PENDING' || user.risk_profile === 'EXPIRED') &&
      user.kyc_date !== '' &&
      kycDate.isBefore(today)
    ) {
      return false;
    } else {
      return true;
    }
  }
  checkSubscriptionEligibility(userRP: string, pouchRP: string) {

    const riskProfiles = ['low', 'moderate', 'high'];

    if (
      !riskProfiles.includes(userRP.toLowerCase()) ||
      !riskProfiles.includes(pouchRP.toLowerCase())
    ) {
      return 'invalid';
    }

    // Assign numerical values to risk profiles for comparison
    const userRPValue = userRP.toLowerCase();
    const pouchRPValue = pouchRP.toLowerCase();
   
    if(userRPValue === pouchRPValue){
      return 'same';
    }else{
      return 'notSame';
    }
    // Check eligibility based on risk profiles
    if (userRPValue === 'low' && pouchRPValue === 'low') {
      return 'same';
    } else if (userRPValue === 'moderate' && (pouchRPValue === 'low' || pouchRPValue === 'moderate')) {
      return 'same';
    } else if (userRPValue === 'high') {
      return 'same';
    } else {
      return 'notSame';
    }
  }
  subscribe() {
    if (!this.checkSubscriptionActive()) {
      const dialog: any = this.dialog.open(WarningKycRpPopupComponent, {
        data: {
          message: 'Error in upload KYC.. Please upload',
          title: 'Error',
          status: 'Fail'
        },
        // height: '18rem',
        width: '50rem'
      });
      return;
    }

    let user: any = localStorage.getItem('user');
    let parsedUser: any = JSON.parse(user || '{}');

    let rp = this.checkSubscriptionEligibility(
      parsedUser.attributes.risk_profile,
      this.pouchDetail.risk_profile
    );
    if (!this.showRpWarning && rp == 'notSame') {
      const dialog = this.dialog.open(RiskProfileComponent, {
        data: [
          parsedUser.attributes.risk_profile,
          this.pouchDetail.risk_profile,
          this.pouchDetail.name
        ]
      });
      dialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.showRpWarning = true;
          // this.continueSubscribe();
          this.router.navigateByUrl('/onboarding/questionaire')   
        } else {
          // this.router.navigateByUrl('/investment')
        }
      });
    } else {
      this.continueSubscribe();
    }
  }
  continueSubscribe() {
    if (this.checkSubscriptionActive()) {
      this.parent.scroll_bottom()
      if (localStorage.getItem('user')) {
        if (this.tabIndex == 0) {
          this.tabIndexChanger.emit(1);
        }
        
        // else {
          if (this.byPassToPayment) {
            this.bypassToPaymentHandler.emit(this.byPassToPayment);
          } else {
            this.router.navigateByUrl(`/subscription-plan/${this.pouchDetail.id}`);
          }
        // }
      } else {
        this.router.navigateByUrl(`/auth/login`);
      }
    } else {
      const dialog: any = this.dialog.open(WarningKycRpPopupComponent, {
        data: {
          message: 'Error in upload KYC.. Please upload',
          title: 'Error',
          status: 'Fail'
        },
        height: '18rem',
        width: '50rem'
      });
    }
  }
  investBtnDisable = false;
  executeTrade() {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    if (subdomain === 'wealthy') {
      this.executePopup('wealthy');
    } else if (subdomain === 'bajajbroking') {
      this.executePopup('bajajbroking')
     } else {
      this.investSmallcase();
    }
  }

  executePopup(partner:string) {
    let subscriber = this.userData.id;
    let data = {
      pouch_id: Number(this.pouchId),
      subscriber_id: subscriber,
      stock_price_date: moment(new Date(this.since)).format('YYYY-MM-DD'),

    };

    this.pouchService.getPouchSubscriberStocksReturns(data).subscribe((res: any) => {

      let getResponse = {
        data: res[1]?.pouch_subscriber_trades.filter((x: any) => x.txn_status !== "PLACED"),
        isAnyPlaced:res[1]?.pouch_subscriber_trades?.some((x: any) => x.txn_status == "PLACED"),
        pouch_id: Number(this.pouchId),
        subscriber_id: subscriber,
        invesment_amount:this.getRound(this.subscriberSubscription[0]?.investment_amount),
        partner:partner
      };


      const dialog: any = this.dialog.open(ExecuteTradePopupComponent, {
        width: '700px',
        data: getResponse,
        autoFocus: false
      }).afterClosed().subscribe((resp) => {
        if (resp == 'openUpdate') {
          this.updateSubscriberInvestmentAmount()
        } else {
          if (resp) {
            this.updateSubscriberListHandler.emit();
          }
         }

      });

    });
  }
  investSmallcase() {
    this.parent.scroll_bottom()

    if (this.tabIndex == 0) {
      this.tabIndexChanger.emit(1);
      this.investBtnDisable = false;
    }
    //  else {
      this.investBtnDisable = true;
      this.pouchService
        .getTransactionId({
          pouch_id: this.pouchDetail.id,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          stock_price_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0]
        })
        .subscribe(
          (response: any) => {
            let id = response.data.transactionId;
            let error = response.errors;
            let body :any= {
              transactionId: id
            }
            let brokers =JSON.parse(sessionStorage.getItem('brokers')||'{}')
            if (brokers) {
              body['brokers']=brokers.brokers
}
            if (!error) {
             try {
              this.gatewayInstance
              .triggerTransaction(body)
              .then(
                (txnResponse: any) => {
                  let body = {
                    pouch_id: this.pouchDetail.id,
                    txn_id: id,
                    from_date: new Date(
                      new Date(
                        localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
                      ).setDate(
                        new Date(
                          localStorage.getItem('stock_price_uploaded_date')?.split('T')[0] || ''
                        ).getDate() - 7
                      )
                    )
                      .toISOString()
                      .split('T')[0],
                    to_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
                    subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
                    api_name: 'sc_txn_resp',
                    api_response_data: {
                      api_name: 'sc_txn_resp',
                      api_id: id,
                      api_response: txnResponse
                    }
                  };
                  const dialogRef = this.dialogRef.open(CommonDialogWaitingComponent, {
                    disableClose: true
                  });
                  this.http
                    .post(environment.baseUrl_8083 + `smartvalues/func/saveapiresponse`, body)
                    .subscribe((res: any) => {
                      if (
                        res[0].update_pouch_subscriber_trade_status.err === '-' &&
                        res[1].insert_pouch_subscriber_trade_status.err === '-' &&
                        res[2].insert_pouch_subscriber_trades.err === '-'
                      ) {
                        // this.pouchComposition.getSubscriberReturns();
                        // dialogRef.close();
                        this.updateTabeleHandler.emit({ dialog: dialogRef });
                      } else {
                        this.notifyService.showError('Error: Invalid', '');
                      }
                    });
                },
                (error: any) => {
                  let body = {
                    pouch_id: this.pouchDetail.id,
                    txn_id: id,
                    subscriber_id: this.pouchDetail.id,
                    api_name: 'sc_txn_resp',
                    api_response_data: {
                      api_name: 'sc_txn_resp',
                      api_id: id,
                      api_response: {
                        error: error.message
                      }
                    }
                  };

                  this.http
                    .post(environment.baseUrl_8083 + `smartvalues/func/saveapiresponseerr`, body)
                    .subscribe((res: any) => {
                      if (res[0].update_pouch_subscriber_trades.err === '-') {
                        // this.pouchComposition.getSubscriberReturns();
                        this.updateTabeleHandler.emit();
                      }
                      this.notifyService.showError(`Error : ${error.message}`, '');
                    });
                }
              );
             } catch (error) {
              
             }
            } else {
              this.notifyService.showError('Error: Invalid', '');
            }
            this.investBtnDisable = false;
          },
          (error: any) => {
            //Error callback
            console.error('error caught in component', error);

            this.notifyService.showError(`Error:${error.error.errors[0]}`, '');
            this.investBtnDisable = false;
          }
        );
    // }
  }

  subscriptionMonth: any;
  getSubscriptionTypeText(val?: any) {
    this.subscriptionMonth = val;

    if (val === 'MONTHLY') {
      return 'Monthly subscription';
    }
    if (val === 'QUARTERLY') {
      return 'Quarterly subscription';
    }
    if (val === 'HALF-YEARLY') {
      return 'Half yearly subscription';
    }

    return '-';
  }

  getRound(val: any) {
    if (val) {
      let num: any = Number(Math.round(val).toFixed(2))
      if (isNaN(num)) {
        return val;
      } else {
        return num;
      }
      return;
    } else {
      return val;
    }
  }

  pouchEndDate: any;
  expiredPouch: boolean = false;
  pouchExpireToday: boolean = false;
  switchDateLeft: boolean = true;
  totalDaysLeft: any;
  SubscriptionEndDate: any;
  daysLeftForRenewal() {
    if (this.subscriptionStatus?.[0]?.end_date) {
      const endDateMoment = _moment(this.subscriptionStatus?.[0]?.end_date).endOf('day');
      // Uncomment the below line if you want to hardcode the end date for testing
      // const endDateMoment = _moment('2023-08-20T00:00:00Z').endOf('day');
      this.pouchEndDate = endDateMoment;
      const todayMoment = _moment();
      let daysLeft = endDateMoment.diff(todayMoment, 'days');
      this.totalDaysLeft = daysLeft;
      if (daysLeft <= 0 && endDateMoment.isSame(todayMoment, 'day')) {
        this.pouchExpireToday = true;
      } else if (daysLeft <= 0 && !endDateMoment.isSame(todayMoment, 'day')) {
        this.expiredPouch = true;
      } else if (daysLeft <= 7) {
        this.switchDateLeft = false;
        this.expiredPouch = false;
        this.pouchExpireToday = false;
      } else {
        this.switchDateLeft = true;
        this.expiredPouch = false;
        this.pouchExpireToday = false;
      }
    }
  }
  openRenew() {
    if (!this.checkSubscriptionActive()) {
      const dialog: any = this.dialog.open(WarningKycRpPopupComponent, {
        data: {
          message: 'Error in upload KYC.. Please upload',
          title: 'Error',
          status: 'Fail'
        },
        // height: '18rem',
        width: '50rem'
      });
      return;
    }
    let user: any = localStorage.getItem('user');
    let parsedUser: any = JSON.parse(user || '{}');

    let rp = this.checkSubscriptionEligibility(
      parsedUser.attributes.risk_profile,
      this.pouchDetail.risk_profile
    );
    if (!this.showRpWarning && rp == 'notSame') {
      const dialog = this.dialog.open(RiskProfileComponent, {
        data: [
          parsedUser.attributes.risk_profile,
          this.pouchDetail.risk_profile,
          this.pouchDetail.name
        ]
      });
      dialog.afterClosed().subscribe((res: any) => {
        if (res) {
          this.showRpWarning = true;
          // this.continueSubscribe();
          this.router.navigateByUrl('/onboarding/questionaire')
        } else {
          // this.router.navigateByUrl('/investment')
        }
      });
    } else {
  


      let subscriberPayload: any = {
        stock_price_date: _moment().format('YYYY-MM-DD'),
        pouch_id: this.pouchDetail.id,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id
      };
      this.subscriberService.getPouchSubscriberReturns(subscriberPayload).subscribe((res) => {
        if (res) {
          
          let data = {
            pouchId: this.pouchDetail.id,
            pouchReturn: res,
            invAmnt: this.subscriberSubscription[0]?.investment_amount,
            agreementSigned: this.getAgreement(),
            agreement_details:this.agreementStatus,
            subscriptionStatus:this.subscriptionStatus
          };

          let popup = this.dialog.open(SubscriptionRenewPopupComponent, {
            data
          });
        }
      });
    }



  
  }

  makePayment() {
    let d = JSON.parse(sessionStorage.getItem('pouchCreation') || '{}');
    this.publicService
      .intiatePaymentGatewaysvf({
        subscriber_id: d.subscriber_id,
        redirect_url: `${window.location.host}/investment`,
        subscription_freq: d.subscription_freq,
        sv_feature_name: 'create_pouch',
        promo_code: ''
      })
      .subscribe((res: any) => {
        if (res) {
          this.redirectToPayment(res);
        }
      });
  }
  redirectToPayment(data: any) {
    this.dialog.open(PaymentLoaderComponent, {
      data: data,
      panelClass: 'custom-modalbox'
    });
  }

  getAgreement() {
    if (Object.keys(this.agreementStatus).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // update subscriber investment amount
  updateSubscriberInvestmentAmount(): void {


    if (!this.subscriptionStatus) {
      console.error('Subscription status is not available.');
      return;
    }

    const { investment_amount, pouch_id, public___pouch_subscriber_subscriptions } =
      this.subscriptionStatus[0];

    if (
      !public___pouch_subscriber_subscriptions ||
      public___pouch_subscriber_subscriptions.length === 0
    ) {
      console.error('No subscriptions available.');
      return;
    }

    const { subscription_freq } = public___pouch_subscriber_subscriptions[0];

    const dialog: MatDialogRef<AmountUpdatePopupComponent, AmountUpdatePopupData> =

      this.dialog.open(AmountUpdatePopupComponent, {
        data: {
          pouchName:this.pouchDetail.name,
          pouchId: pouch_id as string,
          pouchFrequency: subscription_freq as string,
          subscriberInvestmentAmount: investment_amount as string,
          min_investment: this.min_investment,
          subscriberID: JSON.parse(localStorage.getItem('user') || '{}').id,
          pouchStatus: this.pouchExpireToday || this.expiredPouch
        }
      });

    dialog.afterClosed().subscribe((res: any) => {
      if (res?.sellDownValue === 'Sell Down' || res.sellDownValue === 'Top Up') {
        let payload = {
          docs: {
            investment_amount: res.data.investment_amount
          },
          docsi: [
            {
              pouch_id: res.data.pouch_id,
              subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
              investment_amount: res.data.investment_amount,
              change_amount: res.data.change_amount,
              from_date: _moment(localStorage.getItem('stock_price_uploaded_date')).format(
                'YYYY-MM-DD'
              )
            }
          ],
          pouch_id: res.data.pouch_id,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          change_date: _moment(localStorage.getItem('stock_price_uploaded_date')).format(
            'YYYY-MM-DD'
          ),
          review_date: _moment(localStorage.getItem('stock_price_uploaded_date')).format(
            'YYYY-MM-DD'
          )
        };
        this.subscriberService.changeInvestment(payload).subscribe((res: any) => {
          if (res) {
            let popup = this.dialog.open(SucessPopupComponent, {
              data: {
                isRenew: 'sellAmount',
                order: '',
                amount: '',
                no: '',
                id: payload.pouch_id
              }
            });
            popup.afterClosed().subscribe((res) => {
              this.parentcomp.tabIndex = 1;
              this.parentcomp.intialLoad(true);
              this.parentcomp.composition.refreshPendingTable();
              this.parentcomp.refreshBanner();
            });
          }
        });
      }
      if (res === 'liquidate') {


              this.parentcomp.tabIndex = 1;
              this.parentcomp.intialLoad(true);
              this.parentcomp.composition.refreshPendingTable();
              this.parentcomp.refreshBanner();

      }
    });
  }
  unsubscribe(){
    const dialog= this.dialogRef.open(DeletePopupComponent,{
      data:{
        message:`Please confirm whether you want to unsubscribe the pouch`,
        title: '',
        type: 'unsubscribe',
        pouchName: this.pouchDetail.name
      }
  })
    dialog.afterClosed().subscribe({
      next:(res)=>{
        if(res.type=='yes'){
          const body={
            "subscriber_id":localStorage.getItem('id'),
            "pouch_id":this.pouchDetail.id
          }

          this.pouchService.unSubscribePouch(body).subscribe({
            next:()=>{
              this.notifyService.showSuccess(`Unsubscribed the pouch ${this.pouchDetail.name} sucessfully`,'')
              this.router.navigateByUrl('/investment')
            },
            error:(e)=>{
              this.notifyService.showError(e.error.error,'')
            }
          })
        }
      }
    })
  }



}
