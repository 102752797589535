<div class="pouch-detail-container mt-25">
  <div class="row row__overview">
    <div class="col-md-12">
      <div class="row">
        <div class="row--title mb-15">Overview</div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row widget_bg">
            <!-- <div class="widget-cell col sm-mb-20">
							<div class="cell_heading">Stock Universe</div>
							<div class="cell_value"> {{ pouchTimeframeDetail ? pouchTimeframeDetail.universe_name : "" }} </div>
						</div> -->
            <!-- <div class="row">
							<div class="col">
								<div class="row widget_bg"> -->
            <div class="widget-cell col">
              <div class="cell_heading">Stock Universe</div>
              <div class="cell_value">
                {{ pouchTimeframeDetail ? pouchTimeframeDetail.universe_name : '' }}
              </div>
            </div>
            <div class="widget-cell col sm-mb-20">
              <div class="cell_heading">Start Date</div>
              <div class="cell_value">
                {{
                  pouchTimeframeDetail ? (pouchTimeframeDetail.start_date | date: 'mediumDate') : ''
                }}
              </div>
            </div>
            <div class="widget-cell col sm-mb-20">
              <div class="cell_heading">Timeframe Size</div>
              <div class="cell_value">
                {{ pouchTimeframeDetail ? pouchTimeframeDetail.timeframe_size : '' }}
              </div>
            </div>
            <div class="widget-cell col sm-mb-20" *ngIf="!isItermediary">
              <div class="cell_heading">Lookback Type</div>
              <div class="cell_value">
                {{
                  pouchTimeframeDetail
                    ? (pouchTimeframeDetail.lookback_type.split('_').join(' ') | titlecase)
                    : ''
                }}
              </div>
            </div>
            <div class="widget-cell col sm-mb-20" *ngIf="!isItermediary">
              <div class="cell_heading">Rank Tolerance</div>
              <div class="cell_value">
                {{ pouchTimeframeDetail ? pouchTimeframeDetail.rank_tolerance : '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form [formGroup]="pouchDetailForm" (ngSubmit)="savePouch($event)" class="example-form">
    <div class="formWrapper mt-40">
      <div class="row">
        <div class="col-md-6 heightAddL">
          <div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="stock_name">
                <th mat-header-cell *matHeaderCellDef class="heading_stocks">Stocks</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.stock_name }}
                </td>
              </ng-container>
              <ng-container matColumnDef="stock_wt">
                <th mat-header-cell *matHeaderCellDef class="w80 heading_weigthage">Weightage</th>
                <td mat-cell *matCellDef="let element" class="w20">
                  <span *ngIf="!canEditWt">{{ roundOfWeightage(element.stock_wt) }}%</span>
                  <input
                    *ngIf="canEditWt"
                    matInput
                    [value]="element.stock_wt"
                    [(ngModel)]="element.stock_wt"
                    [ngModelOptions]="{ standalone: true }"
                    (blur)="updateRowData($event, element)"
                    class="weigthage" />
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="w50">
                  <img
                    hidden
                    *ngIf="!isDisabled"
                    class=""
                    src="../../../../../../assets/images/icons/action.svg"
                    alt=""
                    (click)="canEditWt = !canEditWt" />
                </th>
                <td mat-cell *matCellDef="let element"></td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                class="table_row-value"
                mat-row
                *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div>
        <div class="col-md-6 heightAddR" style="padding-left: 2%">
          <div>
            <div class="row">
              <div class="col-md-8">
                <p class="f-12 text-grey mb-05">Pouch name</p>
                <mat-form-field
                  class="example-full-width mb-20"
                  appearance="outline"
                  style="width: 100%">
                  <input matInput placeholder="" value="" formControlName="pouch_name" />
                </mat-form-field>
                <!-- <div>
                  <p class="f-12 text-grey mb-05">End Date</p>
                  <mat-form-field appearance="outline" class="mb-20">
                    <input
                      matInput
                      [matDatepicker]="picker"
                      placeholder=""
                      formControlName="end_date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div> -->
              </div>
              <div class="col-md-4 drapdrop-box">
                <p class="f-12 text-grey mb-05">Icon</p>
                <div class="drapdrop">
                  Drag the <br />
                  Image here
                </div>
              </div>
              <div class="col-md-6 mb-20 sm-mb-15">
                <p class="f-12 text-grey mb-05">Investment Strategy</p>
                <mat-form-field class="example-full-width" appearance="outline" style="width: 100%">
                  <input matInput placeholder="" value="" formControlName="investment_strategy" />
                </mat-form-field>
              </div>
              <div class="col-md-6 mb-20 sm-mb-15">
                <p class="f-12 text-grey mb-05">Minimum Investment</p>
                <!-- <p class="f-12 text-black mb-05">{{minInvestment}}</p> -->
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="width: 100%; pointer-events: none">
                  <input
                    matInput
                    type="number"
                    placeholder=""
                    formControlName="minimum_investment"
                    [disabled]="true" />
                </mat-form-field>
              </div>
              <div class="col-md-6 mb-20 sm-mb-15">
                <p class="f-12 text-grey mb-05">Risk Profile</p>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-select formControlName="risk_profile">
                    <mat-option [value]="f.name" *ngFor="let f of risk_profiles">
                      {{ f.name }}
                    </mat-option>
                    <mat-divider></mat-divider>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6 mb-20 sm-mb-15">
                <div class="mt-30">
                  <section class="example-section">
                    <mat-checkbox
                      *ngIf="logginUserRole === 'admin'"
                      [(ngModel)]="isPublicChecked"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="togglePublic($event)"
                      [disabled]="checkDiasble('public')"
                      class="example-margin"
                      ><span class="f-12 text-grey">Public</span></mat-checkbox
                    >
                    <mat-checkbox
                      [(ngModel)]="isHideChecked"
                      (change)="toggleHide($event)"
                      [ngModelOptions]="{ standalone: true }"
                      [disabled]="checkDiasble('hide')"
                      class="example-margin px-2"
                      ><span class="f-12 text-grey">Hide</span></mat-checkbox
                    >
                  </section>
                </div>
              </div>
            </div>
            <!-- <div class="row align-items-center">
                            <div class="col-md-6 mb-20">
                                <p class="f-12 text-grey mb-05">Frequency of review</p>
                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-select formControlName="review_freq" (selectionChange)="onReviewFeqChange()">
                                        <mat-option [value]="f.name" *ngFor='let f of frequency_review'>
                                            {{f.name}}
                                        </mat-option>
                                        <mat-divider></mat-divider>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 mt-0 sm-mb-20"
                                [ngClass]="{'rDate' : (pouchDetailForm.controls['review_freq'].value !== 'DAILY')}"
                                (click)="openBox()">
                                Select Review {{(pouchDetailForm.controls['review_freq'].value != 'WEEKLY') ? 'Date'
                                :
                                'Day'}}
                            </div>
                            <div class="col-md-2 f-14">{{reviewDate}}</div>
                        </div> -->
            <!-- <div class="row">
              <div class="col-md-6 mb-20 sm-mb-15">
                <p class="f-12 text-grey mb-05">Monthly subscription charges</p>
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="width: 100%"
                >
                  <input
                    type="number"
                    matInput
                    placeholder=""
                    value=""
                    min="0"
                    formControlName="monthly_subscriptions"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6 mb-20 sm-mb-15">
                <p class="f-12 text-grey mb-05">Yearly subscription charges</p>
                <mat-form-field
                  class="example-full-width"
                  appearance="outline"
                  style="width: 100%"
                >
                  <input
                    type="number"
                    matInput
                    placeholder=""
                    value=""
                    min="0"
                    formControlName="annual_subscriptions"
                  />
                </mat-form-field>
              </div>
            </div> -->
            <div class="col-md-6 mb-20 sm-mb-15">
              <div class="">
                <section class="example-section">
                  <mat-checkbox
                    [(ngModel)]="stopSubscription"
                    (change)="stopSubscription = $event.checked"
                    [ngModelOptions]="{ standalone: true }"
                    class="example-margin px-2"
                    ><span class="f-12 text-grey">Stop Subscription</span></mat-checkbox
                  >
                </section>
              </div>
            </div>
            <div class="row">
              <p class="f-12 text-grey mb-05">Benchmark Index</p>
              <mat-form-field appearance="outline" class="mx-2" style="width: 90%">
                <mat-select formControlName="benchmarkIndex">
                  <mat-option [value]="f.name" *ngFor="let f of benchMarkOptions | async">
                    {{ f.name }}
                  </mat-option>
                  <mat-divider></mat-divider>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row subscription" [hidden]="logginUserRole === 'partner' || logginUserRole === 'partner_admin'">
              <div class="col-md-12 mb-20 sm-mb-15">
                <p class="f-12 text-grey mb-05">
                  Subscription
                  <span class="f-12 error mb-05">{{ subscribtionError }}</span>
                </p>
                <div class="sub-table">
                  <table>
                    <tr class="header-style">
                      <th></th>
                      <th>Monthly<br />{{ monthlySub }}</th>
                      <th>Quarterly<br />{{ quarterlySub }}</th>
                      <th>Half Yearly<br />{{ halfYearlySub }}</th>
                    </tr>
                    <tr>
                      <td class="lStyle">Percentage</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="number"
                            formControlName="month_percent"
                            matInput
                            (change)="sub_percentage('MONTHLY', $event)" />
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['month_percent'].touched &&
                              pouchDetailForm.controls['month_percent'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="number"
                            matInput
                            formControlName="quarter_percent"
                            (change)="sub_percentage('QUARTERLY', $event)" />
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['quarter_percent'].touched &&
                              pouchDetailForm.controls['quarter_percent'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="number"
                            matInput
                            formControlName="half_percent"
                            (change)="sub_percentage('HALF-YEARLY', $event)" />
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['half_percent'].touched &&
                              pouchDetailForm.controls['half_percent'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="lStyle">Value</td>
                      <td>
                        <mat-form-field
                          appearance="outline"
                          (change)="sub_amount('MONTHLY', $event)">
                          <input matInput type="number" formControlName="month_val" />
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['month_val'].touched &&
                              pouchDetailForm.controls['month_val'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field
                          appearance="outline"
                          (change)="sub_amount('QUARTERLY', $event)">
                          <input matInput type="number" formControlName="quarter_val" />
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['quarter_val'].touched &&
                              pouchDetailForm.controls['quarter_val'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field
                          appearance="outline"
                          (change)="sub_amount('HALF-YEARLY', $event)">
                          <input matInput type="number" formControlName="half_val" />
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['half_val'].touched &&
                              pouchDetailForm.controls['half_val'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="lStyle">Max / Min of two</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <mat-select
                            (selectionChange)="sub_type('MONTHLY', $event)"
                            formControlName="month_type">
                            <mat-option value="MAX">Max of Both</mat-option>
                            <mat-option value="MIN">Min of Both</mat-option>
                            <mat-option value="BOTH">Both</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['month_type'].touched &&
                              pouchDetailForm.controls['month_type'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <mat-select
                            (selectionChange)="sub_type('QUARTERLY', $event)"
                            formControlName="quarter_type">
                            <mat-option value="MAX">Max of Both</mat-option>
                            <mat-option value="MIN">Min of Both</mat-option>
                            <mat-option value="BOTH">Both</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['quarter_type'].touched &&
                              pouchDetailForm.controls['quarter_type'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <mat-select
                            (selectionChange)="sub_type('HALF-YEARLY', $event)"
                            formControlName="half_type">
                            <mat-option value="MAX">Max of Both</mat-option>
                            <mat-option value="MIN">Min of Both</mat-option>
                            <mat-option value="BOTH">Both</mat-option>
                          </mat-select>
                          <mat-error
                            *ngIf="
                              pouchDetailForm.controls['half_type'].touched &&
                              pouchDetailForm.controls['half_type'].invalid
                            ">
                          </mat-error>
                        </mat-form-field>
                      </td>
                    </tr>

                    <tr>
                      <td class="lStyle">Agg Text</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="text"
                            formControlName="month_agg"
                            matInput
                            (input)="aggInput('MONTHLY', $event)" />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="text"
                            matInput
                            formControlName="quarter_agg"
                            (input)="aggInput('QUARTERLY', $event)" />
                        </mat-form-field>
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="text"
                            matInput
                            formControlName="half_agg"
                            (input)="aggInput('HALF-YEARLY', $event)" />
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="lStyle">Discount Percentage</td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="number"
                            formControlName="discount_month_percent"
                            matInput
                             />
                        </mat-form-field>
                        <!-- (change)="sub_percentage('MONTHLY', $event)" -->
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="number"
                            matInput
                            formControlName="discount_quarter_percent"
                             />
                        </mat-form-field>
                        <!-- (change)="sub_percentage('QUARTERLY', $event)" -->
                      </td>
                      <td>
                        <mat-form-field appearance="outline">
                          <input
                            type="number"
                            matInput
                            formControlName="discount_half_percent"
                             />
                             <!-- (change)="sub_percentage('HALF-YEARLY', $event)" -->
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr></tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="row">
              <p class="f-12 text-grey mb-05">Description</p>
              <mat-form-field class="example-full-width" appearance="outline">
                <textarea
                  matInput
                  placeholder=""
                  value=""
                  rows="8"
                  formControlName="description"></textarea>
              </mat-form-field>
              <div class="d-flex justify-content-end btnWrapper btn-group">
                <button mat-button (click)="onCancelClick()" class="theme-btn border-btn">
                  cancel
                </button>
                <button mat-button class="theme-btn primary-btn">save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
