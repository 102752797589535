<div class="popup-section pouch-width">
  <div class="popup-header d-flex justify-content-between">
    <p class="f-16 fw-600">Renew Subscription Plans</p>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <div class="popup-body">
    <div class="col-md-12">
      <div class="auto_pay_container">
        <div>
          <p class="f-16 fw-600">Subscription Plans</p>
  
          <div>
            <mat-radio-group [(ngModel)]="selected">
              <ul>
                <li *ngFor="let ele of subscriptionsList" class="mt-2">
                  <mat-radio-button
                    color="primary"
                    [value]="ele.rowIndex"
                    (change)="radioChanged($event, ele)"
                    >{{
                      getOptionsText(ele.subscription_freq, ele.subscription_type)
                    }}</mat-radio-button
                  ><br />
                </li>
              </ul>
            </mat-radio-group>
          </div>
        </div>
        <div>
          <p class="f-16 fw-600"> 
            <mat-checkbox
            [(ngModel)]="auto_pay"
            class="example-margin px-2"
            >Enable Autopay for renewal</mat-checkbox
          >
            </p>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div>
            <p class="f-16 fw-600" style="margin-bottom: 0 !important">Billing Details</p>
            <div class="address">
              <form [formGroup]="form">
                <div>
                  <mat-label>Address</mat-label>
                  <mat-form-field appearance="outline" class="w-100">
                    <textarea
                      [ngClass]="{ border_red: form.get('address')?.invalid }"
                      matInput
                      placeholder="Address"
                      formControlName="address"></textarea>
                  </mat-form-field>
                  <mat-error class="f-12" *ngIf="form.get('address')?.invalid">
                    Invalid Address
                  </mat-error>
                </div>
                <div class="row" style="margin-top: 7px">
                  <div class="col-md-6">
                    <div>
                      <mat-label>Country</mat-label>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-select
                          [ngClass]="{
                            select_error: form.get('country')?.invalid
                          }"
                          placeholder="Country"
                          formControlName="country">
                          <mat-option *ngFor="let c of getCountry" [value]="c.country_name">{{
                            c.country_name
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-error class="f-12" *ngIf="form.get('country')?.invalid">
                        Invalid Country
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <mat-label>State</mat-label>
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-select
                          [ngClass]="{
                            select_error: form.get('state')?.invalid
                          }"
                          placeholder="Select"
                          formControlName="state">
                          <mat-option *ngFor="let s of getStateName" [value]="s.state_name">{{
                            s.state_name
                          }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-error class="f-12" *ngIf="form.get('state')?.invalid">
                        Invalid State
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 5px">
                  <div class="col-md-6">
                    <div>
                      <mat-label>City</mat-label>
                      <mat-form-field appearance="outline" class="w-100">
                        <input
                          [ngClass]="{ border_red: form.get('city')?.invalid }"
                          matInput
                          placeholder="City"
                          formControlName="city" />
                      </mat-form-field>
                      <mat-error class="f-12" *ngIf="form.get('city')?.invalid">
                        Invalid City
                      </mat-error>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <mat-label>Pincode</mat-label>
                      <mat-form-field appearance="outline" class="w-100">
                        <input
                          [ngClass]="{
                            border_red: form.get('pincode')?.invalid
                          }"
                          (keypress)="numberOnly($event); allowNumber($event)"
                          matInput
                          placeholder="Pincode"
                          formControlName="pincode"
                          maxlength="6"
                          type="tel"
                          (paste)="onPasteAllowNumber($event)"
                          (input)="allowNumber($event)" />
                      </mat-form-field>
                      <mat-error class="f-12" *ngIf="form.get('pincode')?.invalid">
                        Invalid Pincode
                      </mat-error>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-md-6 investment">
          <div class="mb-3">
            <p class="f-16 fw-600">AUA</p>
            <mat-form-field appearance="outline" class="w100">
              <input
                [ngClass]="{ border_red: showInv_error }"
                #inv_amnt
                matInput
                type="text"
                [value]="'Rs.' + ' ' + (AUA | number: '1.0-0')"
                [disabled]="true" />
            </mat-form-field>
            <mat-error *ngIf="showInv_error"> Investment Amount is required </mat-error>
          </div>
          <p class="f-16 fw-600">Billing Summary</p>
          <div class="row mb-10">
            <div class="col-md-6 text-left">Pouch</div>
            <div class="col-md-6 text-right fw-500">{{ getPouchName() }}</div>
          </div>
          <div class="row mb-10">
            <div class="col-md-6 text-left">Subscription</div>
            <div class="col-md-6 text-right fw-500">
              {{ getFrequencyPlan() }}
            </div>
          </div>
          <div class="row tbBorder">
            <div class="col-md-6 text-left">Total</div>
            <div class="col-md-6 text-right fw-600 f-16">
              Rs.
              {{
                totalAmount ||
                  getamount(getSelectedSubscriptionFreq(), getSelectedSubscriptionType())
                  | number: '1.0-0'
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-center mt-30">
    <div class="col-md-5 text-center">
      <button class="theme-btn primary-btn mb-20" (click)="renewSubscription(inv_amnt.value)">
        Renew Subscription
      </button>
    </div>
  </div>
</div>
