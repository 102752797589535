<ng-container *ngIf="parent !== 'createPouch'">
  <div class="performace-component">
    <div class="row d-flex rect align-items-center pt-10 pb-30">
      <div class="col-12">
        <div class="d-flex w_100">
          <div class="w_100">
            <ul class="d-flex ul-design responsive_style">
              <ng-container *ngIf="userType !== 'admin'">
                <li class="align-self-center" style="min-width: 111px">
                  <span class="fw-400 f-14">{{
                    radioSelected === 0 ? 'My Subscription' : pouchDetail.heading_text
                  }}</span>
                </li>
                <li class="align-self-center px-1">
                  <span class="fw-700 f-14">vs</span>
                </li>
                <li class="align-self-center px-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Equity Large & Mid Cap </mat-label>
                    <mat-select
                      [(ngModel)]="selectedUniverse"
                      placeholder="--Select--"
                      (selectionChange)="onIndexChange()">
                      <mat-option [value]="item.name" *ngFor="let item of universeList">{{
                        item.name
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <li>
                  <div class="md-mt-15 since" *ngIf="userType === 'public'">
                    <mat-form-field appearance="outline">
                      <input
                        matInput
                        [matDatepicker]="picker"
                        placeholder=""
                        [max]="uploadedDate"
                        [(ngModel)]="since"
                        (dateChange)="sinceDateChangeHandler($event)" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                </li>
              </ng-container>
              <ng-container *ngIf="userType === 'admin'">
                <ng-container *ngIf="radioSelected !== 0">
                  <li>
                    <p class="f-12 text-grey mb-05" style="padding-bottom: 9px">Pouch Name</p>
                    <span class="fw-400 f-14">{{
                      radioSelected === 0 ? 'My Subscription' : pouchDetail.heading_text
                    }}</span>
                  </li>
                </ng-container>
                <ng-container *ngIf="radioSelected === 0">
                  <li>
                    <div>
                      <p class="f-12 text-grey mb-05">Select Subscriber</p>
                    </div>
                    <mat-form-field appearance="outline">
                      <mat-select
                        (selectionChange)="changeSubscriber()"
                        [(ngModel)]="selectedSubscriber">
                        <ng-container *ngIf="subList">
                          <mat-option
                            [ngStyle]="{
                              display:
                                subscriber.name === pouchDetail.heading_text ? 'none' : 'visible'
                            }"
                            *ngFor="let subscriber of subList"
                            [value]="subscriber.subscriber_id"
                            >{{ subscriber?.subscriber_first_name }}
                            {{ subscriber?.subscriber_last_name }}</mat-option
                          >
                        </ng-container>
                      </mat-select>
                    </mat-form-field>
                  </li>
                </ng-container>
                <li class="align-self-center px-1 pt-20">
                  <span class="fw-700 f-14 mt-10">vs</span>
                </li>
                <li class="align-self-center px-3">
                  <div>
                    <p class="f-12 text-grey mb-05">Select Universe</p>
                  </div>
                  <mat-form-field appearance="outline">
                    <mat-label>Equity Large & Mid Cap </mat-label>
                    <mat-select
                      [(ngModel)]="selectedUniverse"
                      placeholder="--Select--"
                      (selectionChange)="onIndexChange()">
                      <mat-option [value]="item.name" *ngFor="let item of universeList">{{
                        item.name
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
              </ng-container>
            </ul>
            <ng-container *ngIf="radioSelected === 0">
              <mat-radio-group
                *ngIf="userType === 'admin' || userType === 'partner' || userType === 'partner_admin'"
                [(ngModel)]="radioSelectedSubscriber"
                aria-label="Select an option"
                (change)="valueChangeSubscriber($event)">
                <mat-radio-button [value]="0">
                  <span class="fw-400 f-14">Active</span>
                </mat-radio-button>
                <mat-radio-button class="px-3" [value]="1"
                  ><span class="fw-400 f-14">Inactive</span></mat-radio-button
                >
              </mat-radio-group>
            </ng-container>
          </div>
        </div>
        <div class="row mt-4">
          <ul class="d-flex mobile_style">
            <li class="align-self-center">Show backdated performance for last</li>
            <li class="align-self-center reset_margin" style="margin-left: 16px">
              <div class="d-flex datenum-area">
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '1m' }"
                    (click)="getChartData('1m')"
                    >1m</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '6m' }"
                    (click)="getChartData('6m')"
                    >6m</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '1y' }"
                    (click)="getChartData('1y')"
                    >1y</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '3y' }"
                    (click)="getChartData('3y')"
                    >3y</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '5y' }"
                    (click)="getChartData('5y')"
                    >5y</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === 'MAX' }"
                    (click)="getChartData('MAX')"
                    >MAX</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="row w-100">
          <ul class="d-flex mobile_style">
            <li  class="min_height">
              <p class="is_web">
                Value of Rs 100 invested once since <br />
                <span class="fw-600">inception of this pouch</span> would be
              </p>
              <p class="is_mobile">
                Value of Rs 100 invested once since
                <span class="fw-600">inception of this pouch</span> would be
              </p>
            </li>
            <li style="margin-left: 40px" class="display-grid min_height">
              <span class="text-blue f-12">{{
                pouchDetail
                  ? selectedSubscriber == pouchDetail.heading_text
                    ? pouchDetail.heading_text
                    : getNameOfSubscriber(selectedSubscriber)
                  : selectedSubscriber === 'Pouch'
                    ? 'Pouch'
                    : getNameOfSubscriber(selectedSubscriber)
              }}</span>
              <span style="margin-top: -14px">{{ pouchSince ? 'Rs. ' : '' }}{{ pouchSince }}</span>
            </li>
            <li style="margin-left: 40px" class="display-grid min_height">
              <span class="text-orange f-12">{{ selectedUniverse }}</span>
              <span style="margin-top: -14px">{{ indexSince ? 'Rs. ' : '' }}{{ indexSince }}</span>
            </li>
            <li style="margin-left: 40px" class="display-grid min_height">
              <span class="f-12 fw-400 --grey2 mr-10">Annualised Return</span>

              <ng-container *ngIf="cagr < 0">
                <span class="f-14" style="margin-top: -14px; color: red">
                  ( {{ myMath.abs(cagr) || 0 }} %)
                </span>
              </ng-container>
              <ng-container *ngIf="cagr >= 0">
                <span class="f-14" style="margin-top: -14px"> {{ myMath.abs(cagr) || 0 }} % </span>
              </ng-container>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="graph" style="width: 100%;">
            <app-chart
              [chartData]="chart"
              [datesData]="dates"
              [pouchName]="pouchName"
              [indexName]="indexName"
              [minIndex]="minPercent"
              [maxIndex]="maxPercent"
              [duration]="selectedDuration"></app-chart>
          </div>
        </div>
        <!-- <div class="f-16 mt-40 mb-10">
          <p class="fw-500">Ratios</p>
          <div class="table-con">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Smart Values vs Benchmark</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>
        
              <ng-container matColumnDef="pe_ratio">
                <th mat-header-cell *matHeaderCellDef>PE Ratio</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.pe_ratio }}
                </td>
              </ng-container>
        
              <ng-container matColumnDef="pb_ratio">
                <th mat-header-cell *matHeaderCellDef>PB Ratio</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.pb_ratio }}
                </td>
              </ng-container>
       
              <ng-container matColumnDef="sharpe_ratio">
                <th mat-header-cell *matHeaderCellDef>Sharpe Ratio</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.sharpe_ratio }}
                </td>
              </ng-container>
              <ng-container matColumnDef="div_yield">
                <th mat-header-cell *matHeaderCellDef>Div.Yield(%)</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.div_yield }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="parent === 'createPouch'">
  <div class="performace-component">
    <div class="row d-flex rect align-items-center pt-10 pb-30">
      <div class="col-12">
        <div class="d-flex">
          <div class="">
            <ul class="d-flex ul-design">
              <ng-container>
                <ng-container *ngIf="radioSelected !== 0">
                  <li>
                    <p class="f-12 text-grey mb-05" style="padding-bottom: 9px">Pouch Name</p>
                    <span class="fw-400 f-14">{{ 'Pouch' }}</span>
                  </li>
                </ng-container>

                <li class="align-self-center px-1 pt-20">
                  <span class="fw-700 f-14 mt-10">vs</span>
                </li>
                <li class="align-self-center px-3">
                  <div>
                    <p class="f-12 text-grey mb-05">Select Universe</p>
                  </div>
                  <mat-form-field appearance="outline">
                    <mat-label>Equity Large & Mid Cap </mat-label>
                    <mat-select
                      [(ngModel)]="selectedUniverse"
                      placeholder="--Select--"
                      (selectionChange)="onIndexChange()">
                      <mat-option [value]="item.name" *ngFor="let item of universeList">{{
                        item.name
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="row mt-4">
          <ul class="d-flex">
            <li class="align-self-center" >Show backdated performance for last</li>
            <li class="align-self-center reset_margin" style="margin-left: 16px">
              <div class="d-flex datenum-area">
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '1m' }"
                    (click)="getChartData('1m')"
                    >1m</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '6m' }"
                    (click)="getChartData('6m')"
                    >6m</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '1y' }"
                    (click)="getChartData('1y')"
                    >1y</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '3y' }"
                    (click)="getChartData('3y')"
                    >3y</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === '5y' }"
                    (click)="getChartData('5y')"
                    >5y</span
                  >
                </div>
                <div class="datenum">
                  <span
                    class="cursor"
                    [ngClass]="{ 'fw-700': selectedDuration === 'MAX' }"
                    (click)="getChartData('MAX')"
                    >MAX</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="row w-100">
          <ul class="d-flex">
            <li>
              <p>
                Value of Rs 100 invested once since <br />
                <span class="fw-600">inception of this pouch</span> would be
              </p>
            </li>
            <li style="margin-left: 40px" class="display-grid">
              <span class="text-blue f-12">{{
                pouchDetail
                  ? selectedSubscriber == pouchDetail.heading_text
                    ? pouchDetail.heading_text
                    : getNameOfSubscriber(selectedSubscriber)
                  : selectedSubscriber === 'Pouch'
                    ? 'Pouch'
                    : getNameOfSubscriber(selectedSubscriber)
              }}</span>
              <span style="margin-top: -14px">{{ pouchSince ? 'Rs. ' : '' }}{{ pouchSince }}</span>
            </li>
            <li style="margin-left: 40px" class="display-grid">
              <span class="text-orange f-12">{{ selectedUniverse }}</span>
              <span style="margin-top: -14px">{{ indexSince ? 'Rs. ' : '' }}{{ indexSince }}</span>
            </li>
            <li style="margin-left: 40px" class="display-grid">
              <span class="f-12 fw-400 --grey2 mr-10">Annualised Return</span>
              <span class="f-14" style="margin-top: -14px" *ngIf="cagr > 0">{{ cagr || 0 }} %</span>
              <span class="f-14" style="margin-top: -14px" *ngIf="cagr == 0"> {{ 0.0 }} % </span>
              <span class="f-14" style="margin-top: -14px; color: red" *ngIf="cagr < 0">
                <span>({{ transformtValue(cagr) || 0.0 }}) %</span>
              </span>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="graph">
            <app-chart
              [chartData]="chart"
              [datesData]="dates"
              [pouchName]="pouchName"
              [indexName]="indexName"
              [minIndex]="minPercent"
              [maxIndex]="maxPercent"
              [duration]="selectedDuration"></app-chart>
          </div>
        </div>
        <!-- <div class="f-16 mt-40 mb-10">
          <p class="fw-500">Ratios</p>
          <div class="table-con">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Smart Values vs Benchmark</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
              </ng-container>
        
              <ng-container matColumnDef="pe_ratio">
                <th mat-header-cell *matHeaderCellDef>PE Ratio</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.pe_ratio }}
                </td>
              </ng-container>
           
              <ng-container matColumnDef="pb_ratio">
                <th mat-header-cell *matHeaderCellDef>PB Ratio</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.pb_ratio }}
                </td>
              </ng-container>
             
              <ng-container matColumnDef="sharpe_ratio">
                <th mat-header-cell *matHeaderCellDef>Sharpe Ratio</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.sharpe_ratio }}
                </td>
              </ng-container>
              <ng-container matColumnDef="div_yield">
                <th mat-header-cell *matHeaderCellDef>Div.Yield(%)</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.div_yield }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-end" *ngIf="parent && pouchId">
    <div class="col-2 text-end">
      <button class="theme-btn primary-btn" type="button" (click)="onNextClick($event)">
        Next
      </button>
    </div>
  </div>
</ng-container>
