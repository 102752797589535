import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { union } from '@ngrx/store/src/action_creator';

import { Observable } from 'rxjs/internal/Observable';
import { map, startWith } from 'rxjs/operators';

import { cloneDeep } from 'lodash';
import { LoggerService } from 'src/app/common/services/logger.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { PouchService } from 'src/app/modules/investment-pouches/services/pouch.service';
import { UniverseService } from 'src/app/modules/investment-pouches/services/universe.service';
import { MoreService } from 'src/app/modules/more/services/more.service';
import { NotificationService } from 'src/app/services/notification.services';

import { DatepickerComponent } from '../../../../../common/components/datepicker/datepicker.component';
import { UtilsService } from '../../../../../common/services/utils.service';
import { Load, AddEdit, EmptyStateLoad } from '../../../store/actions/pouch.action';
import { PouchState, selectPouchState } from '../../../store/pouch.state';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { ReviewdateComponent } from '../reviewdate/reviewdate.component';
import { TimeFrameDialogComponent } from '../timeframe-dialog/timeframe-dialog.component';

export interface period {
  name: string;
}
@Component({
  selector: 'app-timeframe',
  templateUrl: './timeframe.component.html',
  styleUrls: ['./timeframe.component.scss']
})
export class TimeframeComponent implements OnInit {
  isZscore: boolean = false;
  isValue: boolean = false;
  intermediatryCheckBox: any;
  isReviewFreqValue: boolean = false;
  isPartnerAdvConfig: boolean = true;
  ResetYears: number = 3;
  pouchType = 'PRICE';
  ryears = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  retain_trendedStocks: string = 'Y';
  frequency_review: any = [
    {
      name: 'DAILY'
    },
    {
      name: 'WEEKLY'
    },
    {
      name: 'MONTHLY'
    },
    {
      name: 'QUARTERLY'
    },
    {
      name: 'HALF YEARLY'
    }
  ];

  lookBack_type = [
    {
      name: 'Rolling Period',
      value: 'ROLLING_PERIOD'
    },
    {
      name: 'Fixed Start Date',
      value: 'FIXED_START_DATE'
    }
  ];

  days: any = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  pouchState: any;
  getState: Observable<any>;
  timeFrameData: any;
  isAverage = false;
  universeList: any[];
  todayDate: any;
  timeframeForm: FormGroup;
  recommendedStocks: any;
  viewAll: boolean;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  periodCtrl = new FormControl();
  filteredPeriods: Observable<string[]>;
  periods: any[];
  allPeriods: string[] = ['1 month'];
  recommendedStocksByWeight: any[];
  reviewDate: any = '';
  uploadedDate: any;
  isItermediary: boolean = false;
  itermediaryRT: any = 1;
  INTERMEDIARY: string = 'INTERMEDIARY';
  short: boolean = false;
  medium: boolean = true;
  long: boolean = false;
  selectedPeriods: any = [9];
  excludeFNOStocks = false;

  @ViewChild('periodInput', { static: false })
  periodInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @Output() changeTabEvent = new EventEmitter();
  logginUserRole: any;
  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    private store: Store<PouchState>,
    private pouchService: PouchService,
    private utilsService: UtilsService,
    private universeService: UniverseService,
    private sharedService: SharedService,
    private notifyService: NotificationService,
    private moreSercice: MoreService
  ) {
    this.getState = this.store.select(selectPouchState);
    this.todayDate = new Date();
    this.filteredPeriods = this.periodCtrl.valueChanges.pipe(
      startWith(null),
      map((period: string | null) => (period ? this._filter(period) : this.allPeriods.slice()))
    );
  }

  ngOnInit(): void {
    this.isItermediary = false;
    this.authService.onUserUpdate.subscribe((data) => {
      this.logginUserRole = data?.UserAttributes[2].Value;
    });

    if (!this.logginUserRole) {
      const storageUser: any = localStorage.getItem('user');
      const user = JSON.parse(storageUser);
      this.logginUserRole = user.attributes.role;
      if (this.logginUserRole === 'ria' || this.logginUserRole === 'partner' || this.logginUserRole === 'partner_admin') {
        this.isItermediary = true;
        this.moreSercice
          .getReferrers({
            is_active: 'Y',
            referrer_id: user.attributes.referrer_id
          })
          .subscribe({
            next: (res: any) => {

              if (res[0]?.referrers) {
                this.itermediaryRT = res[0]?.referrers[0].rt;
              } else {
                this.itermediaryRT = 1;
              }
              if (this.logginUserRole === 'partner' || this.logginUserRole === 'ria' || this.logginUserRole === 'partner_admin') {
                try {
                  if (res[0]?.referrers[0]?.adv_config) {
                    this.isPartnerAdvConfig = res[0]?.referrers[0].adv_config;
                  } else {
                    this.isPartnerAdvConfig = false;
                  }
                } catch (e) {
                  this.isPartnerAdvConfig = false;
                }
              }
            },
            error: (e) => {

              this.itermediaryRT = 1;
            }
          });
      }
    }
    this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    const date = new Date(this.uploadedDate);
    this.reviewDate = Number(date.getDate());
    this.createTimeframeForm();
    this.periods = [];
    this.universeList = [];

    this.universeService.getUniverseList().subscribe((res: any) => {
      this.universeList = res[0].universes;
    });
    this.getState.subscribe((state) => {
      this.pouchState = state;
      // let universes = state.universes
      // if(universes){
      //   this.universeList = [...universes];
      // }
    });
    if (!this.pouchState.pouch) {
      this.store.dispatch(new EmptyStateLoad({}));
    }

    for (let i = 2; i <= 120; i++) {
      this.allPeriods.push(`${i} months`);
    }
  }

  createTimeframeForm() {
    this.timeframeForm = new FormGroup({
      universe_name: new FormControl('', { validators: [Validators.required] }),
      reset_years: new FormControl(0, {
        validators: this.logginUserRole === 'admin' ? [Validators.required] : []
      }),
      start_date: new FormControl(new Date(this.uploadedDate), {
        validators: [Validators.required]
      }),
      timeframe_size: new FormControl('', {
        validators: [Validators.required]
      }),
      lookback_periods: this.isItermediary
        ? new FormControl('')
        : new FormControl(this.periods, {
            validators: [Validators.required]
          }),
      lookback_type: this.isItermediary
        ? new FormControl('')
        : new FormControl('', { validators: [Validators.required] }),
      rank_tolerance: this.isItermediary
        ? new FormControl('')
        : new FormControl('', { validators: [Validators.required] }),
      review_freq: new FormControl('MONTHLY', {
        validators: [Validators.required]
      }),
      review_freq_day: new FormControl(this.reviewDate, {
        validators: [Validators.required]
      }),
      tf_wt: new FormControl('DISTINCT', {
        validators: []
      }),
      pouch_type: new FormControl('PRICE', {
        validators: []
      })
    });
  }
  selectPeriod(event: any, period: any) {
    if (event.checked) {
      if (this.selectedPeriods.indexOf(period) == -1) {
        this.selectedPeriods.push(period);
      }
    } else {
      if (this.selectedPeriods.length == 1) {
        if (period == 6) {
          event.source.checked = true;
        } else if (period == 9) {
          event.source.checked = true;
        } else if (period == 12) {
          event.source.checked = true;
        }
        return;
      } else {
        this.selectedPeriods.splice(this.selectedPeriods.indexOf(period), 1);
      }
    }
  }

  dateChangeHandler(event: any) {
    let startDate = this.timeframeForm.controls['start_date'].value;
    this.reviewDate = Number(startDate.getDate());
    this.timeframeForm.controls['review_freq_day'].setValue(Number(startDate.getDate()));
  }

  displayUniverseStockList(universeName: any) {
    let stocks: any;
    this.pouchService.getStockListByUniverse({ universe_name: [universeName] }).subscribe((res) => {
      stocks = res;
      let dialogData = {
        universe: universeName,
        universeStocks: stocks
      };
      this.dialog.open(DialogBoxComponent, { data: dialogData });
    });
  }

  onReviewFeqChange() {
    this.isReviewFreqValue = false;
    this.reviewDate = '';
    this.timeframeForm.controls['review_freq_day'].setValue('');
    if (this.timeframeForm.controls['review_freq'].value != 'WEEKLY') {
      const date = new Date(this.timeframeForm.controls['start_date'].value);
      this.reviewDate = Number(date.getDate());
      this.timeframeForm.controls['review_freq_day'].setValue(Number(date.getDate()));
    }
  }

  openBox() {
    this.isReviewFreqValue = false;
    if (this.timeframeForm.controls['review_freq'].value === '') {
      return;
    } else if (this.timeframeForm.controls['review_freq'].value !== 'DAILY') {
      const dialogRef = this.dialog.open(ReviewdateComponent, {
        data: {
          reviewFeqName: this.timeframeForm.controls['review_freq'].value
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.reviewDate =
            this.timeframeForm.controls['review_freq'].value == 'WEEKLY'
              ? this.days[Number(result.date) - 1]
              : Number(result.date);
          this.timeframeForm.controls['review_freq_day'].setValue(Number(result.date));
        }
      });
    }
  }

  createNew() {
    this.router.navigateByUrl('investment/create-universe/pouch');
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      if ((value || '').trim()) {
        this.periods.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.timeframeForm.controls['lookback_periods'].setValue(this.periods);
    }
  }

  remove(period: string): void {
    const index = this.periods.indexOf(period);

    if (index >= 0) {
      this.periods.splice(index, 1);
      this.timeframeForm.controls['lookback_periods'].setValue(this.periods);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // this.periods.push(event.option.viewValue);
    this.periods.push({
      label: event.option.viewValue,
      date: new Date(this.uploadedDate)
    });
    this.periodInput.nativeElement.value = '';
    this.periodCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allPeriods.filter((period) => period.toLowerCase().indexOf(filterValue) === 0);
  }

  generateTimeframe() {
    if (this.timeframeForm.controls['review_freq_day'].value == '') {
      this.isReviewFreqValue = true;
      return;
    }

    if (this.timeframeForm.valid) {
      let dialogRef = this.dialog.open(TimeFrameDialogComponent);
      let timeframeFormControls = this.timeframeForm.controls;
      let months = this.isItermediary
        ? this.selectedPeriods
        : timeframeFormControls['lookback_periods'].value?.map((period: any) => {
            let splitPeriod = period.label.split(' ');
            return Number(splitPeriod[0]);
          });

      // let startDates = timeframeFormControls['lookback_periods'].value?.map((period :any) => {
      //   return period.date.toISOString();
      // });

      if (timeframeFormControls['review_freq'].value === 'DAILY') {
        let startDate = this.pouchState.pouch?.start_date;
        const datetime: any[] =
          startDate && startDate.indexOf('T') !== -1 ? startDate.split('T') : [];
        if (datetime.length > 1) {
          const spittedDate: any[] = datetime[0].split('-');
          timeframeFormControls['review_freq_day'].setValue(spittedDate[2]);
        }
      }

      let isPrice: boolean = this.isSelected('Price') ? true : false;
      let startDate = timeframeFormControls['start_date'].value;

      if (this.uploadedDate.split('T')[0] != new Date(startDate).toISOString().split('T')[0]) {
        startDate = startDate.setDate(startDate.getDate() + 1);
      }
      //startDate = startDate.setDate(startDate.getDate() + 1)

      let timeframeData = {
        universe_name: timeframeFormControls['universe_name'].value,
        start_date: new Date(startDate).toISOString(),
        review_date: new Date(startDate).toISOString(),
        timeframe_size: timeframeFormControls['timeframe_size'].value,
        lookback_periods: `${months.join(',')}`,
        // "lookback_startdates" :  `${startDates.join(',')}`,
        lookback_type: this.isItermediary
          ? 'FIXED_START_DATE'
          : timeframeFormControls['lookback_type'].value,
        rank_tolerance: this.isItermediary
          ? this.itermediaryRT
          : timeframeFormControls['rank_tolerance'].value,
        pouch_type: timeframeFormControls['pouch_type'].value,
        stock_price_date: this.uploadedDate,
        review_freq: timeframeFormControls['review_freq'].value,
        review_freq_day:isNaN( Number(this.timeframeForm.controls['review_freq_day'].value))?null: Number(this.timeframeForm.controls['review_freq_day'].value),
        reset_years:
          this.logginUserRole === 'admin' ? timeframeFormControls['reset_years'].value : 3,
        retain_stocks: this.logginUserRole === 'admin' ? this.retain_trendedStocks : 'Y',
        tf_wt: timeframeFormControls['tf_wt'].value,
        exclude_non_fno: this.excludeFNOStocks ? 'Y' : 'N'
      };

      this.pouchType = timeframeFormControls['pouch_type'].value;
      const promise = this.pouchService.generateTimeframe(timeframeData);
      this.isAverage = timeframeFormControls['tf_wt'].value === 'AVERAGE' ? true : false;
      promise
        .then((res: any) => {
          this.recommendedStocks = res;
          dialogRef.close();
          if (this.isItermediary) {
            this.onNextClick();
          }
        })
        .catch((err: any) => {

          this.notifyService.showError('', err);
          dialogRef.close();
        });
      // this.pouchService.generateTimeframe(timeframeData).subscribe(
      //   (res) => {

      //     this.recommendedStocks = res;
      //     dialogRef.close();
      //     if (this.isItermediary) {
      //       this.onNextClick();
      //     }
      //   },
      //   (e) => {

      //     this.notifyService.showError('', e.error.error.message);
      //   },()=>{
      //     this.dialog.closeAll();
      //   }
      // );

      if (this.uploadedDate.split('T')[0] != new Date(startDate).toISOString().split('T')[0]) {
        timeframeFormControls['start_date'].value.setDate(new Date(startDate).getDate() - 1);
      }
    }
    // this.dialog.closeAll();
  }
  trendStockChangeEvent(e: any) {
    if (e.checked) {
      this.retain_trendedStocks = 'Y';
    } else {
      this.retain_trendedStocks = 'N';
    }
  }
  no_of_stocks = '';
  universeChange() {

    this.timeframeForm.controls['timeframe_size'].setValue(null);
  }
  changeTimeFrameEvent(e: any) {
    let value = e.target.value;
    let findResult = this.universeList?.find(
      (e: any) => this.timeframeForm.value['universe_name'] == e?.name
    );
    if (findResult) {
      if (value > findResult.no_of_stocks) {
        this.timeframeForm.controls['timeframe_size'].setErrors({
          incorrect: true
        });
        this.no_of_stocks = `${findResult.no_of_stocks}`;
      } else {
        this.timeframeForm.controls['timeframe_size'].setErrors(null);
        this.no_of_stocks = '';
      }
    }
  }
  onNextClick() {
    // if(this.timeframeForm.valid){
    let isPrice: boolean = this.isSelected('Price') ? true : false;

    let pouch = {
      pouch_id: this.recommendedStocks.pouch_id,
      pouch_type: this.recommendedStocks.pouch_type,
      universe_name: this.timeframeForm.controls['universe_name'].value,
      start_date: this.timeframeForm.controls['start_date'].value.toISOString(),
      timeframe_size: this.timeframeForm.controls['timeframe_size'].value,
      rank_tolerance: this.isItermediary
        ? this.itermediaryRT
        : this.timeframeForm.controls['rank_tolerance'].value,
      lookback_type: this.isItermediary
        ? 'FIXED_START_DATE'
        : this.timeframeForm.controls['lookback_type'].value,
      public___pouch_stocks: [],
      // "public___pouch_sel_stocks_byprice": this.recommendedStocks.selected_stocks_price,
      // "public___pouch_sel_stocks_byvolume": this.recommendedStocks.selected_stocks_volume,
      public___pouch_timeframe_stocks: this.recommendedStocks.timeframe_stocks
    };

    if (this.isSelected('ZSCORE')) {
      pouch.public___pouch_stocks = this.recommendedStocks.selected_stocks_zscore;
    } else {
      pouch.public___pouch_stocks = this.recommendedStocks.selected_stocks_price;
    }
    // pouch.public___pouch_stocks = isPrice
    //   ? this.recommendedStocks.selected_stocks_price
    //   : // : this.recommendedStocks.selected_stocks_volume;
    //     this.recommendedStocks.selected_stocks_zscore;

    const statePouch = cloneDeep(this.pouchState);
    statePouch.pouch = pouch;

    this.store.dispatch(new AddEdit(statePouch));
    this.changeTabEvent.emit({
      tabId: '1',
      pouchId: this.recommendedStocks.pouch_id,
      startDate: this.timeframeForm.controls['start_date'].value
    });
    // }
  }

  private selectedLink: string = 'Price';
  setradio(e: string): void {
    this.selectedLink = e;
    this.isZscore = this.isValue = false;
    this.generateTimeframe();
  }

  isSelected(name: string): boolean {
    if (!this.selectedLink) {
      return false;
    }
    return this.selectedLink === name;
  }

  showZscore() {
    this.isZscore = !this.isZscore;
    this.isValue = !this.isValue;
  }
  showPrice() {
    this.isValue = !this.isValue;
  }
  onViewAll() {
    this.viewAll = !this.viewAll;
  }

  roundOfWeightage(value: any) {
    return Math.round(value * 100);
  }

  sortLookBackDates(data: any) {
    return data && data.length > 0 ? data.sort() : data;
  }
  excludeFNO(e: any) {
    if (e.checked) {
      this.excludeFNOStocks = true;
    } else {
      this.excludeFNOStocks = false;
    }
  }
  openDateDialog(event: any, item: any) {
    const Dialogue: any = this.utilsService.openDialog(DatepickerComponent, {}, 500);
    Dialogue.afterClosed().subscribe((result: any) => {
      if (result) {
        LoggerService.log(' this.dataSource => ', result);
        item.date = result;
      }
    });
  }
  stockSelectionChange(e: any) {
    if (e.value === 'ZSCORE') {
      // this.isZscore = true;
      this.selectedLink = 'ZSCORE';
    } else {
      // this.isZscore = false;
      this.selectedLink = 'Price';
    }
  }
  getAverage(type: string, data: any) {
    const stockRanks = data.t.stock_ranks; // Get the stock ranks object
    const dates = data.x; // Get the array of dates

    let avgZScoreRankDenominator = 0;
    let avgZScoretotalPriceRank = 0;

    let totalZScoreRank = 0;
    let totalPriceRank = 0;

    // Loop through the dates and sum the z-score and price ranks
    dates.forEach((date: any) => {
      const ranks = stockRanks[date];
      if (ranks?.zscore_rank) {
        totalZScoreRank += ranks?.zscore_rank || 0;
        avgZScoreRankDenominator = avgZScoreRankDenominator + 1;
      } else {
        totalZScoreRank += ranks?.zscore_rank || 0;
      }

      if (ranks?.price_rank) {
        totalPriceRank += ranks?.price_rank || 0;
        avgZScoretotalPriceRank = avgZScoretotalPriceRank + 1;
      } else {
        totalPriceRank += ranks?.price_rank || 0;
      }
      // totalZScoreRank += ranks?.zscore_rank || 0;
      // totalPriceRank += ranks?.price_rank || 0;
    });

    // Calculate the average z-score and price ranks
    const avgZScoreRank = totalZScoreRank / avgZScoreRankDenominator;
    const avgPriceRank = totalPriceRank / avgZScoretotalPriceRank;
    if (type == 'Price') {
      return avgPriceRank.toFixed(2);
    } else {
      return avgZScoreRank.toFixed(2);
    }
  }
}
